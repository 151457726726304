import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'es-map-widget';
import React, { useCallback, useState } from 'react';
import {
    CONTROLLER_KEYS,
    CONTROLLER_LABELS,
    MAP_LAYER_NAMES,
    YIELD_PLANT_OPTIONS,
    YIELD_TYPE_OPTIONS,
} from '../../../constants';
import { useLayout } from '../../../providers/LayoutContext';
import YieldDataController from '../components/YieldDataController';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const yieldDefault = CONTROLLER_KEYS.RAPE_SEED;

const getDefaultSelected = (checkedItems) => {
    if (
        checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE] &&
        Object.entries(checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE])?.length > 0
    ) {
        return CONTROLLER_KEYS.YIELD_REFERENCE;
    }
    if (
        checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED] &&
        Object.entries(checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED])?.length > 0
    ) {
        return CONTROLLER_KEYS.YIELD_CALCULATED;
    }
    return CONTROLLER_KEYS.YIELD_REFERENCE;
};

const YieldController = () => {
    const map = useMap();
    const { checkedItems, handleRadioItemCheck } = useMenu();
    const { matchesLgUp } = useLayout();
    const [isReferenceLayerVisible, setIsReferenceLayerVisible] = useState(
        !!checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE]?.isLayerVisible
    );
    const [isCalculatedLayerVisible, setIsCalculatedLayerVisible] = useState(
        !!checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED]?.isLayerVisible
    );
    const [referenceLayerData, setReferenceLayerData] = useState({
        isLayerVisible: isReferenceLayerVisible,
        type: checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE]?.type || YIELD_TYPE_OPTIONS.REFERENCE,
        plant: checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE]?.isLayerVisible
            ? checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE]?.plant
            : CONTROLLER_KEYS.RAPE_SEED,
    });
    const [calculatedLayerData, setCalculatedLayerData] = useState({
        isLayerVisible: isReferenceLayerVisible,
        type: checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED]?.type || YIELD_TYPE_OPTIONS.REAL,
        plant: checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED]?.isLayerVisible
            ? checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED]?.plant
            : CONTROLLER_KEYS.RAPE_SEED,
    });
    const [selectedOption, setSelectedOption] = useState(() => getDefaultSelected(checkedItems));

    const setReference = useCallback(
        ({ isLayerVisible, type, plant }) => {
            map.setLayerVisibility(MAP_LAYER_NAMES.YIELD_MAP, isLayerVisible);
            map.setLayerData(MAP_LAYER_NAMES.YIELD_MAP, {
                isLayerVisible,
                type,
                plant: YIELD_PLANT_OPTIONS[plant],
            });
            setReferenceLayerData({ isLayerVisible, type, plant });
            handleRadioItemCheck({ isLayerVisible, type, plant }, CONTROLLER_KEYS.YIELD_REFERENCE);
        },
        [map, setReferenceLayerData]
    );

    const setCalculated = useCallback(
        ({ isLayerVisible, type, plant }) => {
            map.setLayerVisibility(MAP_LAYER_NAMES.YIELD_MAP, isLayerVisible);
            map.setLayerData(MAP_LAYER_NAMES.YIELD_MAP, {
                isLayerVisible,
                type,
                plant: YIELD_PLANT_OPTIONS[plant],
            });
            setCalculatedLayerData({ isLayerVisible, type, plant });
            handleRadioItemCheck({ isLayerVisible, type, plant }, CONTROLLER_KEYS.YIELD_CALCULATED);
        },
        [map, setReferenceLayerData]
    );

    const setPlant = useCallback(
        (plant) => {
            setReference({
                ...referenceLayerData,
                plant,
            });
        },
        [setReference, referenceLayerData]
    );

    const setPlantCalculated = useCallback(
        (plant) => {
            setCalculated({
                ...calculatedLayerData,
                plant,
            });
        },
        [setCalculated, calculatedLayerData]
    );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-1', !matchesLgUp && 'mobile')}
            >
                <MapControllerMenuItem
                    onClick={() => setSelectedOption(CONTROLLER_KEYS.YIELD_REFERENCE)}
                    text={CONTROLLER_LABELS.YIELD_REFERENCE}
                    checkbox={{
                        checked: isReferenceLayerVisible,
                        onChange: (event) => {
                            setIsReferenceLayerVisible((pV) => !pV);
                            setReference({
                                isLayerVisible: !isReferenceLayerVisible,
                                plant: referenceLayerData.plant,
                                type: referenceLayerData.type,
                            });
                            if (event.target.checked) {
                                setIsCalculatedLayerVisible(false);
                                handleRadioItemCheck(null, CONTROLLER_KEYS.YIELD_CALCULATED);
                                setSelectedOption(CONTROLLER_KEYS.YIELD_REFERENCE);
                            } else {
                                handleRadioItemCheck(null, CONTROLLER_KEYS.YIELD_CALCULATED);
                                handleRadioItemCheck(null, CONTROLLER_KEYS.YIELD_REFERENCE);
                                map.setLayerVisibility(MAP_LAYER_NAMES.YIELD_MAP, false);
                            }
                        },
                    }}
                    hasSubMenu
                    isActive={selectedOption === CONTROLLER_KEYS.YIELD_REFERENCE}
                />
                <MapControllerMenuItem
                    onClick={() => setSelectedOption(CONTROLLER_KEYS.YIELD_CALCULATED)}
                    text={CONTROLLER_LABELS.YIELD_CALCULATED}
                    checkbox={{
                        checked: isCalculatedLayerVisible,
                        onChange: (event) => {
                            setIsCalculatedLayerVisible((pV) => !pV);
                            setCalculated({
                                isLayerVisible: !isCalculatedLayerVisible,
                                plant: calculatedLayerData.plant,
                                type: calculatedLayerData.type,
                            });
                            if (event.target.checked) {
                                setIsReferenceLayerVisible(false);
                                handleRadioItemCheck(null, CONTROLLER_KEYS.YIELD_REFERENCE);
                                setSelectedOption(CONTROLLER_KEYS.YIELD_CALCULATED);
                            } else {
                                handleRadioItemCheck(null, CONTROLLER_KEYS.YIELD_CALCULATED);
                                handleRadioItemCheck(null, CONTROLLER_KEYS.YIELD_REFERENCE);
                                map.setLayerVisibility(MAP_LAYER_NAMES.YIELD_MAP, false);
                            }
                        },
                    }}
                    hasSubMenu
                    isActive={selectedOption === CONTROLLER_KEYS.YIELD_CALCULATED}
                />
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-2', !matchesLgUp && 'mobile')}
            >
                <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.YIELD_REFERENCE}>
                    <YieldDataController
                        value={referenceLayerData.plant}
                        setValue={setPlant}
                        defaultValue={
                            checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE]?.isLayerVisible
                                ? checkedItems[CONTROLLER_KEYS.YIELD_REFERENCE]?.plant
                                : yieldDefault
                        }
                        isLayerVisible={isReferenceLayerVisible}
                        menuKey={CONTROLLER_KEYS.YIELD_REFERENCE}
                    />
                </SubMenuWrapper>
                <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.YIELD_CALCULATED}>
                    <YieldDataController
                        value={calculatedLayerData.plant}
                        setValue={setPlantCalculated}
                        defaultValue={
                            checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED]?.isLayerVisible
                                ? checkedItems[CONTROLLER_KEYS.YIELD_CALCULATED]?.plant
                                : yieldDefault
                        }
                        isLayerVisible={isCalculatedLayerVisible}
                        menuKey={CONTROLLER_KEYS.YIELD_REFERENCE}
                    />
                </SubMenuWrapper>
            </Grid>
        </Grid>
    );
};

export default YieldController;
