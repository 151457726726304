import chroma from 'chroma-js';
import { CROP_COLORS, FROST_DAMAGE_COLORS, YIELD_COLORS } from './colors';
import { MAP_LAYER_NAMES } from './mapLayerNames';

const scaleTypes = {
    CONTINUOUS: 'CONTINUOUS',
    DISCRETE: 'DISCRETE',
};

const scales = {
    [MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY]: {
        name: 'Kárkronológia',
        scale: chroma.scale(['#FDB77A', '#EB6040', '#B30000']).domain([0, 50, 100]),
        minMax: ['Nem káros', 'Káros'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.FROST_DAMAGE_CHRONOLOGY]: {
        name: 'Kárkonológia - Fagykáros évek száma',
        scale: [
            chroma(FROST_DAMAGE_COLORS[1]),
            chroma(FROST_DAMAGE_COLORS[2]),
            chroma(FROST_DAMAGE_COLORS[3]),
        ],
        minMax: ['Alacsony', 'Magas'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.CROP]: {
        name: 'Vetéstérkép',
        scale: [
            chroma(CROP_COLORS[1]),
            chroma(CROP_COLORS[2]),
            chroma(CROP_COLORS[3]),
            chroma(CROP_COLORS[4]),
        ],
        minMax: ['Kukorica', 'Őszi ká.', 'Kalász.', 'Naprafo.'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.YIELD_MAP]: {
        name: 'Hozamtérkép',
        scale: [
            chroma(YIELD_COLORS[0]),
            chroma(YIELD_COLORS[2]),
            chroma(YIELD_COLORS[4]),
            chroma(YIELD_COLORS[6]),
            chroma(YIELD_COLORS[8]),
            chroma(YIELD_COLORS[10]),
            chroma(YIELD_COLORS[12]),
            chroma(YIELD_COLORS[14]),
            chroma(YIELD_COLORS[16]),
        ],
        minMax: ['0', '2', '4', '6', '8', '10', '12', '14', '16'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.RADAR]: {
        name: 'Erőforrás allokáció támogatása',
        scale: [chroma('#ff0000'), chroma('#ffff00')],
        minMax: ['Kritikus jelenség', 'MePAR'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.ZONE]: {
        name: 'Relatív biomassza intenzitás  ',
        scale: chroma
            .scale(['#D7191C', '#EB7310', '#DBC810', '#99E630', '#3CBF58', '#00723F'])
            .mode('lch')
            .colors(6),
        minMax: ['alacsony', 'magas'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.CHANGE_MAP]: {
        name: 'Biomassza intenzitás változás',
        scale: chroma.scale(['#995533', '#ffffff', '#008066']).mode('lch').colors(21),
        minMax: ['Negatív', 'Pozitív'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.FROST]: {
        name: 'Fagyveszély mértéke',
        scale: chroma.scale(['#006335', '#d4fa1c', '#bd070c']).mode('lch').colors(21),
        minMax: ['Alacsony', 'Magas'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.DEEP_MAP]: {
        name: 'Mélyfekvésű területek',
        scale: [chroma('#0101ff')],
        minMax: [],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.DAMAGE_MAP]: {
        name: 'Károsodás mértéke',
        scale: chroma.scale(['#662b00', '#ffffff']).mode('lch').colors(21),
        minMax: ['Magas', 'Alacsony'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.NDVI]: {
        name: 'Biomassza intenzitás',
        scale: chroma.scale(['#cc2b33', '#ccff00', '#008033']).mode('lch').colors(21),
        minMax: ['Alacsony', 'Magas'],
        type: scaleTypes.DISCRETE,
    },
};

export { scales, scaleTypes };
